export const AUTOMATION_EDIT = (route, name) => [{
  text          : "1088",
  exact         : true,
  to            : "/automations",
  useTranslation: true
}, {
  text          : name,
  exact         : true,
  to            : `/automations/${route.params.id}`,
  useTranslation: false
}]