import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"
import ThemisAutomationCard from "@/components/automation-card"
import ThemisCascadedInput from "@/components/shared/cascaded-input"
import {
  MAX_CHARACTER_LIMIT,
  VALIDATION_ERROR,
  AUTOMATION_ACTIONS,
  AUTOMATION_TRIGGERS,
  ACTION_VALUE_CATEGORY,
  REPORT_SOURCE_FOR_AUTOMATION,
  AUTOMATION_FIELD_FOR_SOURCE_PHONE
} from "@/constants"
import { mapActions, mapGetters, mapMutations } from "vuex"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"
import { AUTOMATION_EDIT } from "@/constants/bread-crumbs/automation-edit"
import { compareArray } from "@/utils"

export default {
  name      : "Automation",
  components: {
    ThemisInput,
    ThemisDecision,
    ThemisAutomationCard,
    ThemisCascadedInput
  },
  data() {
    return {
      helpCenterUrl: process.env.VUE_APP_HELP_CENTER_URL,
      automation   : {
        name           : null,
        action         : null,
        toDomainId     : null,
        toUserId       : null,
        toGroupId      : null,
        trigger        : AUTOMATION_TRIGGERS.SPEAK_UP_ISSUE_CREATE.value,
        domains        : [],
        channels       : [],
        intakeForms    : [],
        fieldId        : null,
        options        : [],
        stopProcessing : false,
        reportCountries: []
      },
      automationIdForEdit         : null,
      automationNameCharacterLimit: MAX_CHARACTER_LIMIT.AUTOMATION_NAME,
      automationEditStatus        : {
        name           : false,
        action         : false,
        toDomainId     : false,
        toUserId       : false,
        toGroupId      : false,
        trigger        : false,
        domains        : false,
        channels       : false,
        intakeForms    : false,
        fieldId        : false,
        options        : false,
        stopProcessing : false,
        reportCountries: false
      },
      selectedUserOrGroup       : null,
      showAutomationRemoveDialog: false,
      stopProcessingValueChanged: false,
      source                    : REPORT_SOURCE_FOR_AUTOMATION.WEB_OR_APP,
      fieldForSourcePhone       : AUTOMATION_FIELD_FOR_SOURCE_PHONE[0]
    }
  },
  computed: {
    ...mapGetters({
      domains                           : "domains/domains",
      channels                          : "channels/channels",
      optionListItems                   : "optionListItems/optionListItems",
      formTemplates                     : "formTemplates/formTemplates",
      fieldsV2                          : "fields/fieldsV2",
      isAutomationAdded                 : "automations/isAutomationAdded",
      isAddingAutomation                : "automations/isAddingAutomation",
      automationAddError                : "automations/automationAddError",
      isUpdatingAutomation              : "automations/isUpdatingAutomation",
      isAutomationUpdated               : "automations/isAutomationUpdated",
      automationUpdateError             : "automations/automationUpdateError",
      formTemplateConfigurations        : "formTemplateConfigurations/formTemplateConfigurations",
      automations                       : "automations/automations",
      isRemovingAutomation              : "automations/isRemovingAutomation",
      isAutomationRemoved               : "automations/isAutomationRemoved",
      users                             : "users/users",
      groups                            : "groups/groups",
      isAutomationActionSendEmailEnabled: "configurations/isAutomationActionSendEmailEnabled",
      isAutomationActionMoveIssueEnabled: "configurations/isAutomationActionMoveIssueEnabled"
    }),
    isAutomationNameDuplicate() {
      if (this.automationAddError) {
        return this.automationAddError.field === "name" &&
          this.automationAddError.type === VALIDATION_ERROR.TYPE.DUPLICATE
      }
      if (this.automationUpdateError) {
        return this.automationUpdateError.field === "name" &&
          this.automationUpdateError.type === VALIDATION_ERROR.TYPE.DUPLICATE
      }
      return false
    },
    isSourcePhone() {
      return this.source === REPORT_SOURCE_FOR_AUTOMATION.PHONE
    },
    isSourceWebOrApp() {
      return this.source === REPORT_SOURCE_FOR_AUTOMATION.WEB_OR_APP
    },
    domainsForSelection() {
      return this.domains?.filter(domain => !domain.archived)
    },
    channelsForSelection() {
      const channels = []
      if (!this.channels.length || !this.domains.length) {
        return channels
      }

      const { channelIds } = this.validChannelIdsAndIntakeFormIds
      for (const channelId of channelIds) {
        channels.push({
          id  : channelId,
          name: this.channelsMap[channelId].name
        })
      }

      return channels
    },
    intakeFormsForSelection() {
      const intakeForms = []
      if (!this.formTemplates.length || !this.channels.length || !this.domains.length) {
        return intakeForms
      }

      const { intakeFormIds } = this.validChannelIdsAndIntakeFormIds
      for (const intakeFormId of intakeFormIds) {
        intakeForms.push({
          id  : intakeFormId,
          name: this.formTemplatesMap[intakeFormId]?.name
        })
      }

      return intakeForms
    },
    fieldsForSelection() {
      let intakeFormIds = []

      if (!this.intakeFormsForSelection.length || !this.fieldsV2.length) {
        return []
      } else if (!this.automation.channels.length && !this.automation.intakeForms.length) {
        intakeFormIds = this.validChannelIdsAndIntakeFormIds.intakeFormIds
      } else if (this.automation.channels.length && !this.automation.intakeForms.length) {
        for (const channelId of this.automation.channels) {
          intakeFormIds.push(...this.getChannelIntakeFormIds(channelId))
        }
      } else {
        intakeFormIds = this.automation.intakeForms
      }

      let uniqueFieldIds = intakeFormIds.reduce((acc, intakeFormId) => {
        const fieldIds = this.formTemplateConfigurations
          .filter(formTemplateConfiguration => formTemplateConfiguration.formTemplateId === intakeFormId)
          .sort((firstFormTemplateConfiguration, secondFormTemplateConfiguration) =>
            firstFormTemplateConfiguration.sortingOrder - secondFormTemplateConfiguration.sortingOrder)
          .map(formTemplateConfiguration => formTemplateConfiguration.fieldId)
        return acc.concat(fieldIds)
      }, [])
      uniqueFieldIds = [...new Set(uniqueFieldIds)]

      return uniqueFieldIds.map(fieldId => {
        return {
          id        : fieldId,
          systemName: this.fieldsMap[fieldId]?.systemName
        }
      })
    },
    optionListItemsForSelection() {
      let field
      if (this.isSourceWebOrApp) {
        field = this.fieldsMap[this.automation.fieldId]
      }
      if (this.isSourcePhone) {
        field = this.fieldsV2.find(field => field.systemName === "Country")
      }
      return this.optionListItems
        .filter(optionListItem => optionListItem.optionListId === field?.optionListId)
        .sort((firstOptionListItem, secondOptionListItem) =>
          firstOptionListItem.sortingOrder - secondOptionListItem.sortingOrder)
    },
    cascadedOptionListItemsForSelection() {
      if (this.isOptionListCascaded) {
        return this.generateNestedOptionListItems(this.optionListItemsForSelection, true)
      }
    },
    actions() {
      const enabledActions = []
      if (this.isAutomationActionMoveIssueEnabled) {
        enabledActions.push(AUTOMATION_ACTIONS.DOMAIN_CHANGE)
      }
      if (this.isAutomationActionSendEmailEnabled) {
        enabledActions.push(AUTOMATION_ACTIONS.SEND_EMAIL_NOTIFICATION)
      }
      if (enabledActions.length === 1) {
        this.automation.action = enabledActions[0].value
      }
      return enabledActions.map(automationAction => ({
        ...automationAction,
        label: this.$t(automationAction.label)
      }))
    },
    triggers() {
      return Object.values(AUTOMATION_TRIGGERS).map(automationAction => ({
        ...automationAction,
        label: this.$t(automationAction.label)
      }))
    },
    disableAutomationSave() {
      let anyAutomationFieldUpdated = false
      for (const key in this.automationEditStatus) {
        if (this.automationEditStatus[key]) {
          anyAutomationFieldUpdated = true
          break
        }
      }

      const isActionValueSelected = this.isDomainChanged
        ? !!this.automation.toDomainId
        : this.isSendEmailNotification
          ? !!this.automation.toUserId || !!this.automation.toGroupId
          : false

      const isAutomationValid = this.automation.name
      && this.automation.name.length <= this.automationNameCharacterLimit
      && !!this.automation.action
      && isActionValueSelected
      && (
        (
          this.isSourceWebOrApp
          && !!this.automation.fieldId
          && !!this.automation.options.length
        ) || (
          this.isSourcePhone
          && !!this.automation.reportCountries.length
        )
      )

      const isInvalidOrDuplicate = !isAutomationValid || this.isAutomationNameDuplicate

      if (this.automationIdForEdit) {
        return !anyAutomationFieldUpdated || isInvalidOrDuplicate || this.isRemovingAutomation
      }

      return !isAutomationValid || isInvalidOrDuplicate
    },
    fieldsMap() {
      const fieldsMap = {}
      for (const field of this.fieldsV2) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    },
    channelsMap() {
      const channelsMap = {}
      for (const channel of this.channels) {
        channelsMap[channel.id] = channel
      }
      return channelsMap
    },
    formTemplatesMap() {
      const formTemplatesMap = {}
      for (const formTemplate of this.formTemplates) {
        formTemplatesMap[formTemplate.id] = formTemplate
      }
      return formTemplatesMap
    },
    validChannelIdsAndIntakeFormIds() {
      const intakeFormIds = []
      const channelIds    = []
      const domainIds     = this.automation.domains.length
        ? this.automation.domains
        : this.domainsForSelection.map(domain => domain.id)

      for (const channel of this.channels) {
        if (domainIds.includes(channel.domainId)) {
          channelIds.push(channel.id)
          if (this.automation.channels.length === 0 || this.automation.channels.includes(channel.id)) {
            intakeFormIds.push(...channel.formTemplateIds)
          }
        }
      }

      return { intakeFormIds, channelIds }
    },

    /**
     * This method will construct a tree structure in the form
     *                                    root
     *                                      |
     *                domain1    domain2 ... domainN
     *                   |
     *                channel1   channel2           ...channelN
     *                   |
     *              intakeForm1  intakeForm2 ... intakeFormN
     *                   |
     *                field1    field2 ... fieldN
     *                   |
     *               option1    option2 ... optionN
     *
     *  nodes from channel2 to channelN are constructed similarly to node channel1
     */
    constructTree() {
      const tree = {
        name    : "root",
        children: this.domains.map(domain => {
          return {
            domainId: domain.id,
            children: this.channels
              .filter(channel => channel.domainId === domain.id)
              .map(channel => {
                return {
                  channelId: channel.id,
                  children : channel.formTemplateIds.map(formTemplateId => {
                    const formTemplates = this.formTemplateConfigurations.filter(
                      formTemplateConfiguration =>
                        formTemplateConfiguration.formTemplateId === formTemplateId
                    )
                    return {
                      intakeFormId: formTemplateId,
                      children    : formTemplates.map(formTemplate => {
                        const field = this.fieldsMap[formTemplate.fieldId]
                        return {
                          fieldId : field.id,
                          children: this.optionListItems
                            .filter(
                              optionListItem =>
                                optionListItem.optionListId === field.optionListId
                            )
                            .map(optionListItem => optionListItem.name)
                        }
                      })
                    }
                  })
                }
              })
          }
        })
      }
      return tree
    },
    automationForEdit() {
      return this.automations.find(automation => automation.id === this.automationIdForEdit)
    },
    getEditAutomationPayload() {
      const payload = {}
      for (const key of Object.keys(this.automationEditStatus)) {
        if (this.automationEditStatus[key]) {
          switch (key) {
            case "name":
              payload.name = this.automation.name
              break
            case "action":
              payload.action = this.automation.action
              break
            case "trigger":
              payload.trigger = this.automation.trigger
              break
            case "stopProcessing":
              payload.stopProcessing = this.automation.stopProcessing
              break
          }
        }
      }
      return payload
    },
    isAnyTriggerConditionsUpdated() {
      const keysToCheck = ["channels", "intakeForms", "fieldId", "options", "reportCountries"]
      for (const key of keysToCheck) {
        if (this.automationEditStatus[key]) {
          return true
        }
      }
    },
    isAnyActionConditionsUpdated() {
      const keysToCheck = ["toDomainId", "toUserId", "toGroupId"]
      for (const key of keysToCheck) {
        if (this.automationEditStatus[key]) {
          return true
        }
      }
    },
    disableActionButton() {
      return this.isAddingAutomation || this.isUpdatingAutomation || this.isRemovingAutomation
    },
    isOptionListCascaded() {
      return !!this.optionListItemsForSelection.find(optionListItem => optionListItem.parentId)
    },
    usersAndGroups() {

      const users = this.users
        .filter(user => user.enabled || (user.id === this.automation.toUserId && !user.enabled))
        .map(user => ({
          id                   : `${ACTION_VALUE_CATEGORY.USER}_${user.id}`,
          name                 : user.name,
          isGroup              : false,
          disabledButNotDeleted: user.id === this.automation.toUserId && !user.enabled
        }))

      const groups = this.groups.map(group => ({
        id     : `${ACTION_VALUE_CATEGORY.GROUP}_${group.id}`,
        name   : group.name,
        isGroup: true
      }))

      return [{ "header": this.$t("414") }].concat(users).concat([{ "header": this.$t("415") }]).concat(groups)
    },
    isDomainChanged() {
      return this.automation.action === AUTOMATION_ACTIONS.DOMAIN_CHANGE.value
    },
    isSendEmailNotification() {
      return this.automation.action === AUTOMATION_ACTIONS.SEND_EMAIL_NOTIFICATION.value
    },
    actionInnerIcon() {
      if (this.isDomainChanged) {
        return "mdi-arrow-right-thin"
      } else if (this.isSendEmailNotification) {
        return "mdi-email-outline"
      }
    },
    fieldsForSelectionForSourcePhone() {
      return AUTOMATION_FIELD_FOR_SOURCE_PHONE
    }
  },
  methods: {
    ...mapActions({
      addAutomation   : "automations/addAutomation",
      updateAutomation: "automations/updateAutomation",
      removeAutomation: "automations/removeAutomation",
      notify          : "shared/notify"
    }),
    ...mapMutations({
      setAutomationAddError   : "automations/setAutomationAddError",
      setAutomationUpdateError: "automations/setAutomationUpdateError",
      setPageTitle            : "shared/setPageTitle",
      setBreadcrumbs          : "shared/setBreadcrumbs"
    }),
    cancelAutomationAdd() {
      this.$router.push({ name: "automations" })
    },
    handleAutomationSave() {
      if (this.automationIdForEdit) {
        const payload = this.getEditAutomationPayload

        if (this.isAnyTriggerConditionsUpdated) {
          payload.triggerConditions = {
            domainIds : this.automation.domains,
            channelIds: this.automation.channels
          }

          if (this.isSourceWebOrApp) {
            payload.triggerConditions.fieldId         = this.automation.fieldId
            payload.triggerConditions.fieldValues     = this.automation.options
            payload.triggerConditions.formTemplateIds = this.automation.intakeForms
          } else if (this.isSourcePhone) {
            payload.triggerConditions.reportCountries = this.automation.reportCountries
          }
        }

        if (this.isAnyActionConditionsUpdated) {
          if (this.isDomainChanged) {
            payload.actionConditions = {
              toDomainId: this.automation.toDomainId
            }
          } else if (this.isSendEmailNotification) {
            if (this.automation.toUserId) {
              payload.actionConditions = {
                toUserId: this.automation.toUserId
              }
            } else {
              payload.actionConditions = {
                toGroupId: this.automation.toGroupId
              }
            }
          }
        }

        if (Object.keys(payload).length) {
          this.updateAutomation({
            id: this.automationIdForEdit,
            ...payload
          })
        }
      } else {
        const payload = {
          name             : this.automation.name,
          action           : this.automation.action,
          trigger          : this.automation.trigger,
          triggerConditions: {
            domainIds : this.automation.domains,
            channelIds: this.automation.channels
          },
          stopProcessing: this.automation.stopProcessing
        }
        if (this.isSourceWebOrApp) {
          payload.triggerConditions.formTemplateIds = this.automation.intakeForms
          payload.triggerConditions.fieldId         = this.automation.fieldId
          payload.triggerConditions.fieldValues     = this.automation.options
        } else if (this.isSourcePhone) {
          payload.triggerConditions.reportCountries = this.automation.reportCountries
        }
        if (this.isDomainChanged) {
          payload.actionConditions = {
            toDomainId: this.automation.toDomainId
          }
        } else if (this.isSendEmailNotification) {
          if (this.automation.toUserId) {
            payload.actionConditions = {
              toUserId: this.automation.toUserId
            }
          } else {
            payload.actionConditions = {
              toGroupId: this.automation.toGroupId
            }
          }
        }
        this.addAutomation(payload)
      }
    },
    getChannelIntakeFormIds(channelId) {
      return this.channels.find(channel => channel.id === channelId).formTemplateIds
    },
    doSelectedValuesBelongInTree() {
      const rootNode = this.constructTree
      const result   = {
        channelsBelongToDomains    : false,
        intakeFormsBelongToChannels: false,
        fieldBelongsToIntakeForms  : false,
        optionsBelongToField       : false
      }

      const domainIds = this.automation.domains.length
        ? this.automation.domains
        : this.domainsForSelection.map(domain => domain.id)

      const channelIds = this.automation.channels.length
        ? this.automation.channels
        : this.validChannelIdsAndIntakeFormIds.channelIds

      const intakeFormIds = this.automation.intakeForms?.length
        ? this.automation.intakeForms
        : this.validChannelIdsAndIntakeFormIds.intakeFormIds

      const channelsForSelectedDomains     = new Set()
      const intakeFormsForSelectedChannels = new Set()
      const optionsForSelectedField        = new Set()

      for (const domainId of domainIds) {
        const domainNode = rootNode.children.find(child => child.domainId === domainId)
        if (!domainNode) continue

        for (const channelId of channelIds) {
          const channelNode = domainNode.children.find(child => child.channelId === channelId)
          if (!channelNode) continue
          channelsForSelectedDomains.add(channelId)
          result.channelsBelongToDomains = true

          for (const intakeFormId of intakeFormIds) {
            const intakeFormNode = channelNode.children.find(child => child.intakeFormId === intakeFormId)
            if (intakeFormNode) {
              intakeFormsForSelectedChannels.add(intakeFormId)
              result.intakeFormsBelongToChannels = true

              if (intakeFormNode.children.find(child => child.fieldId === this.automation.fieldId)) {
                result.fieldBelongsToIntakeForms = true
              }

              const fieldNode = intakeFormNode.children.find(child => this.automation.fieldId === child.fieldId)
              if (fieldNode) {
                for (const option of this.automation.options) {
                  if (fieldNode.children.includes(option)) {
                    result.optionsBelongToField = true
                    optionsForSelectedField.add(option)
                  }
                }
              }
            }
          }
        }
      }

      if(channelsForSelectedDomains.size && this.automation.channels.length) {
        this.automation.channels = Array.from(channelsForSelectedDomains)
      }
      if (intakeFormsForSelectedChannels.size && this.automation.intakeForms?.length) {
        this.automation.intakeForms = Array.from(intakeFormsForSelectedChannels)
      }
      if (optionsForSelectedField.size) {
        this.automation.options = Array.from(optionsForSelectedField)
      }
      return result
    },
    emptySelectedValuesIfDoesNotMatch() {
      const {
        channelsBelongToDomains,
        intakeFormsBelongToChannels,
        fieldBelongsToIntakeForms,
        optionsBelongToField
      } = this.doSelectedValuesBelongInTree()

      if (!channelsBelongToDomains) {
        this.automation.channels    = []
        this.automation.intakeForms = []
        this.automation.fieldId     = null
        this.automation.options     = []
      }

      if (!intakeFormsBelongToChannels) {
        this.automation.intakeForms = []
        this.automation.fieldId     = null
        this.automation.options     = []
      }
      if (!fieldBelongsToIntakeForms) {
        this.automation.fieldId = null
        this.automation.options = []
      }
      if (!optionsBelongToField) {
        this.automation.options = []
      }
    },
    handleRemoveAutomation() {
      this.removeAutomation(this.automationIdForEdit)
      this.showAutomationRemoveDialog = false
    },
    generateNestedOptionListItems(optionListItems, isMultiple) {
      const optionListItemMap = {}
      const result            = []

      optionListItems.forEach(optionListItem => {
        optionListItemMap[optionListItem.id] = { ...optionListItem, children: [] }
      })

      optionListItems.forEach(optionListItem => {
        const parent = optionListItemMap[optionListItem.parentId]
        if (parent) {
          parent.disabled = !isMultiple
          parent.children.push(optionListItemMap[optionListItem.id])
        } else {
          result.push(optionListItemMap[optionListItem.id])
        }
      })

      return result
    },
    onNodeSelect(selectedNodes, validate) {
      this.automation.options = selectedNodes
      validate(this.automation.options)
    },
    handleCascadeInputBlur(onBlur, validate) {
      onBlur()
      validate(this.automation.options)
    },
    getActionLabel(action) {
      if (action === AUTOMATION_ACTIONS.DOMAIN_CHANGE.value) {
        return AUTOMATION_ACTIONS.DOMAIN_CHANGE.label
      } else if (action === AUTOMATION_ACTIONS.SEND_EMAIL_NOTIFICATION.value) {
        return AUTOMATION_ACTIONS.SEND_EMAIL_NOTIFICATION.label
      }
    },
    getTriggerLabel(action) {
      if (action === AUTOMATION_TRIGGERS.SPEAK_UP_ISSUE_CREATE.value) {
        return AUTOMATION_TRIGGERS.SPEAK_UP_ISSUE_CREATE.label
      }
    },
    handleValidate(value, validate) {
      validate(value)
    },
    getSelectedValue(value) {
      switch (value) {
        case AUTOMATION_ACTIONS.DOMAIN_CHANGE.value:
          return this.$t(AUTOMATION_ACTIONS.DOMAIN_CHANGE.label)
        case AUTOMATION_ACTIONS.SEND_EMAIL_NOTIFICATION.value:
          return this.$t(AUTOMATION_ACTIONS.SEND_EMAIL_NOTIFICATION.label)
        case AUTOMATION_TRIGGERS.SPEAK_UP_ISSUE_CREATE.value:
          return this.$t(AUTOMATION_TRIGGERS.SPEAK_UP_ISSUE_CREATE.label)
      }
    },
    getActionIcon(action) {
      return action === AUTOMATION_ACTIONS.DOMAIN_CHANGE.value
        ? AUTOMATION_ACTIONS.DOMAIN_CHANGE.icon : AUTOMATION_ACTIONS.SEND_EMAIL_NOTIFICATION.icon
    },
    handleSourceChange() {
      if (this.isSourceWebOrApp) {
        this.automation.reportCountries = []
      }
      if (this.isSourcePhone) {
        this.automation.fieldId     = null
        this.automation.options     = []
        this.automation.intakeForms = []
      }
    }
  },
  watch: {
    "automation.domains": {
      handler: function() {
        this.emptySelectedValuesIfDoesNotMatch()
      }
    },
    "automation.channels": {
      handler: function(newValue, oldValue) {
        if (newValue.length !== oldValue.length || !newValue.every(value => oldValue.includes(value))) {
          this.emptySelectedValuesIfDoesNotMatch()
        }
      }
    },
    "automation.intakeForms": {
      handler: function(newValue, oldValue) {
        if (newValue?.length !== oldValue.length || !newValue.every(value => oldValue.includes(value))) {
          this.emptySelectedValuesIfDoesNotMatch()
        }
      }
    },
    "automation.fieldId": {
      handler: function() {
        this.emptySelectedValuesIfDoesNotMatch()
      }
    },
    "automation": {
      deep     : true,
      immediate: true,
      handler  : function(newValue) {

        if (this.automationIdForEdit) {
          if (newValue.name) {
            const name                     = this.automationForEdit.name
            this.automationEditStatus.name = newValue.name !== name
          }
          if (newValue.action) {
            const action                     = this.automationForEdit.action
            this.automationEditStatus.action = newValue.action !== action
          }
          if (newValue.toDomainId) {
            const toDomainId                     = this.automationForEdit.actionConditions.toDomainId
            this.automationEditStatus.toDomainId = newValue.toDomainId !== toDomainId
          }
          if (newValue.toUserId) {
            const toUserId                     = this.automationForEdit.actionConditions.toUserId
            this.automationEditStatus.toUserId = newValue.toUserId !== toUserId
          }
          if (newValue.toGroupId) {
            const toGroupId                     = this.automationForEdit.actionConditions.toGroupId
            this.automationEditStatus.toGroupId = newValue.toGroupId !== toGroupId
          }
          if (newValue.trigger) {
            const trigger                     = this.automationForEdit.trigger
            this.automationEditStatus.trigger = newValue.trigger !== trigger
          }
          if (newValue.channels) {
            const channels                     = this.automationForEdit.triggerConditions.channelIds
            this.automationEditStatus.channels = !compareArray(newValue.channels, channels)
          }
          if (newValue.intakeForms) {
            const intakeForms = this.automationForEdit.triggerConditions.formTemplateIds
            if (intakeForms) {
              this.automationEditStatus.intakeForms = !compareArray(newValue.intakeForms, intakeForms)
            }
          }
          if (newValue.fieldId) {
            const fieldId                     = this.automationForEdit.triggerConditions.fieldId
            this.automationEditStatus.fieldId = newValue.fieldId !== fieldId
          }
          if (newValue.options) {
            const options = this.automationForEdit.triggerConditions.fieldValues
            if (options) {
              this.automationEditStatus.options = !compareArray(newValue.options, options)
            }
          }
          if (newValue.reportCountries) {
            const reportCountries = this.automationForEdit.triggerConditions.reportCountries
            if (reportCountries) {
              this.automationEditStatus.reportCountries = !compareArray(newValue.reportCountries, reportCountries)
            }
          }
          if (this.stopProcessingValueChanged ) {
            const stopProcessing                     = this.automationForEdit.stopProcessing
            this.automationEditStatus.stopProcessing = newValue.stopProcessing !== stopProcessing
          }
        }

        if (newValue.name) {
          this.setAutomationAddError(undefined)
          this.setAutomationUpdateError(undefined)
        }
      }
    },
    isAutomationAdded: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "954"
          })
          this.$router.push({ name: "automations" })
        }
      }
    },
    isAutomationUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "957"
          })
          this.$router.push({ name: "automations" })
        }
      }
    },
    isAutomationRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "1127"
          })
          this.$router.push({ name: "automations" })
        }
      }
    },
    "$route": {
      immediate: true,
      handler  : function(newValue) {
        if (newValue.name === "automation-edit") {
          this.automationIdForEdit = +newValue.params.id
          const automation         = this.automations.find(automation => automation.id === +newValue.params.id)

          const userId = this.users.find(user => user.id === automation.actionConditions.toUserId)
            ? automation.actionConditions.toUserId : null

          const groupId = this.groups.find(group => group.id === automation.actionConditions.toGroupId)
            ? automation.actionConditions.toGroupId : null

          this.automation          = {
            name           : automation.name,
            trigger        : automation.trigger,
            domains        : automation.triggerConditions.domainIds,
            channels       : automation.triggerConditions.channelIds,
            intakeForms    : automation.triggerConditions.formTemplateIds ?? [],
            fieldId        : automation.triggerConditions.fieldId,
            options        : automation.triggerConditions.fieldValues ?? [],
            reportCountries: automation.triggerConditions.reportCountries ?? [],
            action         : automation.action,
            toDomainId     : automation.actionConditions.toDomainId,
            toUserId       : userId,
            toGroupId      : groupId,
            stopProcessing : automation.stopProcessing
          }
          this.selectedUserOrGroup = userId || groupId
            ? userId
              ? `${ACTION_VALUE_CATEGORY.USER}_${automation.actionConditions.toUserId}`
              : `${ACTION_VALUE_CATEGORY.GROUP}_${automation.actionConditions.toGroupId}`
            : null
          this.source              = this.automation.reportCountries.length ?
            REPORT_SOURCE_FOR_AUTOMATION.PHONE : REPORT_SOURCE_FOR_AUTOMATION.WEB_OR_APP
          this.setPageTitle(PAGE_TITLE_WITHOUT_TRANSLATION(automation.name))
          this.setBreadcrumbs(
            AUTOMATION_EDIT({ params: { id: +newValue.params.id } }, automation.name)
          )
        }
      }
    },
    selectedUserOrGroup: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          const [type, idString] = this.selectedUserOrGroup.split("_")
          const id               = Number(idString)

          this.automation.toUserId  = null
          this.automation.toGroupId = null
          type === ACTION_VALUE_CATEGORY.USER
            ? this.automation.toUserId = id
            : this.automation.toGroupId = id
        }
      }
    },
    "automation.stopProcessing": {
      immediate: true,
      handler  : function(newValue, oldValue) {
        if (oldValue !== newValue) {
          this.stopProcessingValueChanged = true
        }
      }
    }
  }
}