export default {
  name : "AutomationCard",
  props: {
    pShowInfo                 : Boolean,
    pShowPrimaryColumn        : Boolean,
    pShowSecondaryColumn      : Boolean,
    pShowThirdColumn          : Boolean,
    pShowFourthColumn         : Boolean,
    pShowSideBySideColumnLeft : Boolean,
    pShowSideBySideColumnRight: Boolean,
    pDividerPrimaryColumn     : Boolean,
    pDividerSecondaryColumn   : Boolean,
    pDividerThirdColumn       : Boolean,
    pDividerFourthColumn      : Boolean,
    pShowAdditionalColumnBelow: Boolean
  }
}